import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { find, flatten, isEmpty } from 'lodash';
import colors from 'constants/colors.json';
import { WarningOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import {
  AILMENTS,
  PackageDisclaimer,
  PackageUniqueCode,
} from '../services/package.static';

type Props = {
  selectedPackages?: PackageUniqueCode[];
  state?: string; // State abbr
};

type DisclaimerStatus = {
  disclaimer: PackageDisclaimer;
  accepted: boolean;
};

export const DisclaimerModal = ({ selectedPackages, state }: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [disclaimersStatus, setDisclaimersStatus] = useState<
    DisclaimerStatus[]
  >([]);

  useEffect(() => {
    if (disclaimersStatus.filter(d => !d.accepted).length === 0) {
      setIsModalVisible(false);
    } else {
      setIsModalVisible(true);
    }
  }, [disclaimersStatus]);
  useEffect(() => {
    if (!state || isEmpty(state) || isEmpty(selectedPackages)) return;
    setDisclaimersStatus((pv: DisclaimerStatus[]) => {
      const packages = AILMENTS.filter(ailment =>
        selectedPackages?.includes(ailment.value),
      );
      const disclaimers = flatten(
        packages.map(item => item?.disclaimers),
      ).filter(disclaimer => {
        const disclaimerStatesAbbr = disclaimer?.states?.map(
          stateName => stateName.abbr,
        );
        return disclaimerStatesAbbr?.includes(state);
      }) as PackageDisclaimer[];
      // Merge new coming disclaimers with the previous state
      return disclaimers?.map(disclaimer => {
        const existingDisclaimer = find(pv, { disclaimer });
        return existingDisclaimer || { disclaimer, accepted: false };
      });
    });
  }, [selectedPackages, state]);

  const handleCloseModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const handleAcceptDisclaimer = useCallback(() => {
    const newDisclaimersStatus = [...disclaimersStatus];
    newDisclaimersStatus.forEach((disclaimerStatus, index) => {
      newDisclaimersStatus[index].accepted = true;
    });
    setDisclaimersStatus(newDisclaimersStatus);
  }, [disclaimersStatus]);

  return (
    <Modal
      footer={null}
      open={isModalVisible}
      onCancel={handleCloseModal}
      closable={false}
      centered
    >
      <div>
        {disclaimersStatus.map((disclaimerStatus, index) => (
          <div className="flex flex-col items-center pb-4">
            <div className="bg-gray-100 p-4 rounded-lg mt-4">
              {/* Title */}
              <div className="text-lg text-center underline">
                {disclaimerStatus?.disclaimer.title}
              </div>

              <p
                css={css`
                  font-family: 'Roboto';
                  font-size: 16px;
                `}
                className="text-center text-info mt-4"
              >
                {disclaimerStatus?.disclaimer?.description}
              </p>
            </div>

            <Button
              type="primary"
              className="mt-4"
              onClick={handleAcceptDisclaimer}
            >
              I Understand!
            </Button>
          </div>
        ))}
      </div>
    </Modal>
  );
};
