import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, Typography } from 'antd';
import { AxiosError } from 'axios';

type Props = {
  visible: boolean;
  onClose: () => void;
  error: AxiosError;
};

export const SignupErrorModal = ({ visible, onClose, error }: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  useEffect(() => {
    setIsModalVisible(visible);
  }, [visible]);

  const handleClose = useCallback(() => {
    setIsModalVisible(false);
    onClose();
  }, []);

  return (
    <Modal
      open={isModalVisible}
      title="Uh oh!"
      onOk={handleClose}
      onCancel={handleClose}
      className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      footer={[
        <Button key="back" onClick={handleClose}>
          Close
        </Button>,
      ]}
    >
      <div>
        <div className="p-5 bg-gray-300 rounded-xl shadow-lg mb-10">
          <p className="font-bold center text-center font-roboto">
            {error?.data?.error?.message}
          </p>
        </div>
        {/* A link to instruction on how to download and onboard on the app */}
        <div className="font-roboto">
          Need assistance? Check out our
          <Typography.Link
            target="_blank"
            href="https://www.getbastion.com/employee-activation-steps"
          >
            {' '}
            onboarding{' '}
          </Typography.Link>
          guide or contact our support.
        </div>
      </div>
    </Modal>
  );
};
